@primary-color: #faad14;

.ant-layout-sider {
    box-shadow: 2px 0 8px 0 rgb(29 35 41 / 5%);
}

.ant-layout-header {
    box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
    background: #ffffff;
    height: auto;
    padding: 0px;
}

.ant-page-header-heading-extra {
    display: flex;
    align-items: center;
}

.ant-page-header-heading {
    align-items: center;
}