.authorization-page-content {
    display: flex;
    justify-content: center;
    overflow: auto;
    height: 100vh;
}

.authorization-card {
    max-width: 400px;
    width: 100%;
    margin: 20px;
    height: max-content;
}

.authorization-page-logo {
    max-height: 150px;
    max-width: 150px;
    height: 100%;
    width: 100%;
}

.authorization-page-languages-dropdown {
    position: fixed;
    top: 20px;
    right: 20px;
}