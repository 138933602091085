.authed-layout-content {
    max-height: calc(100vh - 64px);
    overflow: auto;
    padding: 20px;
}

@media screen and (max-width: 768px) {
    .authed-layout-content {
        max-height: calc(100vh - 72px);
    }
}
