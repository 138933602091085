.header-user-dropdown-image {
    height: 24px;
    width: 24px;
    margin-right: 5px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
}

.header-language-image {
    height: 18px;
    border-radius: 5px;
}

.header-notifications-tab > .ant-tabs-content-holder {
    height: 350px;
    width: 350px;
    overflow: auto;
}

.header-notifications-tab li {
    padding: 10px;
}
